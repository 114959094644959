import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import IncentiveCatalog from "../../IncentiveCatalog/IncentiveCatalog";
import {FormattedMessage} from 'react-intl';

import "./HomepageIncentives.scss";

const HomepageIncentives = ({ incentives }) => {

  return (
    <section className="container pb-0" id="HomepageIncentives">
      <div className="row">
        <div className="col-sm-12 text-center">
            <h2>
              <FormattedMessage 
                  id="homepage.incentives.title"
                  defaultMessage="Explore potential EV incentives and tax credits"
                  description="Homepage Incentives Title"
              />
            </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 offset-md-3 text-center">  
        <p className="lead">      
            <FormattedMessage 
                id="homepage.incentives.subTitle"
                defaultMessage="See how much you could save getting behind the wheel of an EV,
                whether you are buying or leasing. {lineBreak} Incentives are personalized for
                where you live."
                description="Homepage Incentives Sub Title"
                values= {{
                  lineBreak: <br />
                }}
              />
            </p>
            <Link to="/incentives" style={{margin:"12px"}} className="btn btn-ae" role="button">
              <FormattedMessage 
                  id="homepage.incentives.exporeIncentives"
                  defaultMessage="EXPLORE INCENTIVES"
                  description="Explore Incentives Button"
                />
            </Link>
          </div>
        </div>
        <IncentiveCatalog incentives={incentives} category="all" />
      </section>
  );
};

export default HomepageIncentives;

HomepageIncentives.propTypes = {
  incentives: PropTypes.array
};
