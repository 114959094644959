import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import ButtonGroup from "../../shared/InputElements/ButtonGroup";
import Select from "../../shared/InputElements/Select";

const ChooseTaxFilingStatus = ({
  id = "tax-filing-status",
  label = "Tax Filing Status",
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);

  return (
    <>
      <div className="d-none d-md-block">
        <ButtonGroup
          id={id}
          value={userPrefs.get("taxFilingStatus")}
          label={label}
          optionNames={["Single", "Married", "Head of Household"]}
          optionValues={["single", "joint", "head_of_household"]}
          handler={(e, selected) =>
            userPrefs.set({ taxFilingStatus: selected })
          }
          {...rest}
        />
      </div>
      <div className="d-block d-md-none">
        <Select
          id={id}
          value={userPrefs.get("taxFilingStatus")}
          label={label}
          optionNames={["Single", "Married", "Head of Household"]}
          optionValues={["single", "joint", "head_of_household"]}
          handler={e => userPrefs.set({ taxFilingStatus: e.target.value })}
          {...rest}
        />
      </div>
    </>
  );
};

export default ChooseTaxFilingStatus;

ChooseTaxFilingStatus.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string
};