import React, { useEffect } from "react";
import { useIntl } from 'react-intl';
import PropTypes from "prop-types";
import ChargingMap from "../../components/LocationMap/ChargingMap";

import { intlReturner, translateString } from "../../functions/intl/intl";

const MapPage = ({ userLocation, ip, uuid, history, dealerLocations, tabId, title, zipcode }) => {
  const intl = intlReturner(useIntl); 

  useEffect(() => {
    document.title = title;
  });

  return (
    <section className="container">
      <h1 className="hide-offscreen">Map</h1>
      <h2 className="text-center">{translateString(intl, 'chargingStations', 'Charging stations')}</h2>
      <p className="text-center">{translateString(intl, 'chargingStations.subtitle', "find publicly available charging statiosn")}</p>
      <ChargingMap userLocation={userLocation} />
    </section>
  );
};

export default MapPage;

MapPage.propTypes = {
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
