import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import {FormattedMessage} from 'react-intl';
import EVCard from "../EVCard/EVCard";

import "./VehicleCarousel.scss";
import getFilteredData from "../../utils/Helpers/getFilteredData";

const VehicleCarousel = ({ electricVehicles }) => {
  const [firstEv, setFirstEv] = useState(
    Math.floor(electricVehicles.length * Math.random())
  );

  if (!electricVehicles || electricVehicles.length <= 3) return null;

  electricVehicles = getFilteredData(
    [
      {field: "fuel", value: "BEV", count: 1000}
    ], electricVehicles
  )

  const vehicleCount = electricVehicles ? electricVehicles.length : "many";
  const maxIndex = vehicleCount - 1;

  const evIndices = [
    firstEv,
    firstEv + 1 <= maxIndex ? firstEv + 1 : firstEv + 1 - vehicleCount,
    firstEv + 2 <= maxIndex ? firstEv + 2 : firstEv + 2 - vehicleCount,
    firstEv + 3 <= maxIndex ? firstEv + 3 : firstEv + 3 - vehicleCount
  ];

  const filteredEVs = [
    electricVehicles[evIndices[0]],
    electricVehicles[evIndices[1]],
    electricVehicles[evIndices[2]],
    electricVehicles[evIndices[3]]
  ];

  const selectableEvs = filteredEVs.map((ev, i) => {
    return (
      <div
        className={`${"evc-card EVCard selected"}`}
        tabIndex="0"
        key={i}
      >
        <EVCard
          ev={ev}
          hasLinkToEv={true}
          hasLocallyAvailableHidden
          hasEvDetails
        />
      </div>
    );
  });

  const linkstoEvs = filteredEVs.map((ev, i) => {
    return (
      <Link
        to={`${"/vehicles/" + ev.handle}`}
        className="evc-card EVCard"
        key={i}
      >
        <EVCard ev={ev} hasLocallyAvailableHidden />
      </Link>
    );
  });

  const renderVehicles = (
    <>
      <div className="row d-none d-lg-flex d-xl-flex">
        <div className="col-sm-1 text-right">
          <button
            className="btn btn-default"
            style={{ marginTop: "105px", fontSize: "21px" }}
            onClick={() =>
              firstEv === 0 ? setFirstEv(maxIndex) : setFirstEv(firstEv - 1)
            }
          >
            &lsaquo;
          </button>
        </div>
        <div className="col-sm-10">
          <div className="render-cards-container full-width">
            {selectableEvs}
          </div>
        </div>
        <div className="col-sm-1">
          <button
            className="btn btn-default"
            style={{ marginTop: "105px", fontSize: "21px" }}
            onClick={() =>
              firstEv === maxIndex ? setFirstEv(0) : setFirstEv(firstEv + 1)
            }
          >
            &rsaquo;
          </button>
        </div>
      </div>

      <div className="render-cards-container full-width d-flex d-lg-none d-xl-none">
        {linkstoEvs}
      </div>
    </>
  );
  
  return (
    <section className="container pb-0" id="HomepageVehiclesCarousel">
      <div className="row">
        <div className="col-md-10 offset-md-1 text-center">
          <h2>
            <FormattedMessage 
                id="homepage.vehicleCarousel.washington.title"
                defaultMessage="There are {vehicleCount} all-electric vehicles available in Washington state. Discover yours."
                description="Vehicle Carousel Title"
                values= {{
                  vehicleCount: vehicleCount
                }}
              />
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 offset-md-2 text-center">
          <p>
          <FormattedMessage 
              id="homepage.vehicleCarousel.subtitle"
              defaultMessage="With more models than ever, there’s an electric vehicle for everyone. Find the one that fits your lifestyle, and see how much you can save on fuel and maintenance annually."
              description="Vehicle Carousel Title"
              values= {{
                vehicleCount: vehicleCount
              }}
            />
          </p>

        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <Link to="/vehicles" className="btn btn-ae mt-10 find-evs" role="button">
            <FormattedMessage 
                id="homepage.vehicleCarousel.findEVButton"
                defaultMessage="See all vehicles"
                description="Find EV Button"
              />
          </Link>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-12">{renderVehicles}</div>
      </div>
    </section>
  );
};

export default VehicleCarousel;

VehicleCarousel.propTypes = {
  electricVehicles: PropTypes.array
};
