import React from "react";
import PropTypes from "prop-types";
import "./IncentiveCard.scss";
import { FormatAsDollars } from "../../../utils/Helpers/Format";

import isHomepage from "../../../utils/isHomepage";

import iconIncentivePiggy from "../../assets/images/icons/Level2_Charger.svg";

const IncentiveCard = props => {
  const cardBodyClass = isHomepage() ? 'HomeIncentiveCardBody' : 'IncentiveCardBody';
  const incentivePigImg = (
    <img
      src={iconIncentivePiggy}
      alt="piggy icon"
      className="incentive-no-dollar-icon"
    />
  )

  let incentive = props && props.incentive ? props.incentive : {};

  let name = incentive.grantor + " " + incentive.name || "";
  let description = incentive.description || "";
  
  let amountDescription =
    incentive.typical_amount && incentive.typical_amount !== "N/A"
      ? incentive.typical_amount.replace("-$", "-")
      : incentivePigImg;

  let amount =
    incentive.evaluation &&
    incentive.evaluation.amount_in_purchase &&
    parseInt(incentive.evaluation.amount_in_purchase, 10) > 0
      ? parseInt(incentive.evaluation.amount_in_purchase, 10)
      : 0;



  let renderCard = incentive ? (
    <div className="incentive-card-container">
      <div className={`${cardBodyClass}-incentive-card-div-wrapper`}>
        <a
        className="IncentiveCard"
        href={incentive.details_url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={`${cardBodyClass} incentive-card-body`}>
          <p className="incentive-type">{incentive.type}</p>
          <p className="h1 card-title">
            {amount ? FormatAsDollars(amount) : amountDescription}
          </p>
          <p className="h6">{name}</p>
        </div>
        <div className="IncentiveCardBottom">
          <div className="pl-5 pr-5">
            <hr />
          </div>
          <p>
            {description}
          </p>
        </div>
      </a>
      </div>
    </div>
  ) : null;

  return renderCard;
};

export default IncentiveCard;

IncentiveCard.propTypes = {
  incentive: PropTypes.object,
  compact: PropTypes.bool
};
