import React from "react";
import PropTypes from "prop-types";
import {useIntl} from 'react-intl';

import ChargingPinPublic from "../../../assets/images/icons/charger-pin-public.png"
import ChargingPinHighPower from "../../../assets/images/icons/charger-pin-high-power.png";
import ChargingPinDefault from "../../../assets/images/icons/charger-pin-private.png";
import ChargingPinUpGo from "../../../assets/images/icons/charger-pin-up-go.png";
import isSuperChargingStation from '../../../../utils/predicates/isSuperChargingStation';
import { intlReturner, translateString } from "../../../../functions/intl/intl";

import "./ChargingStationsMapLegend.scss";


const ChargingStationsMapLegend = ({ chargingStations }) => {
  const intl = intlReturner(useIntl);

  const hasSuperChargerStations =
    (chargingStations || []).some(station => isSuperChargingStation(station));

  return (
    <div className="legend ChargingStationsMapLegend">
      <div className="charger-type">
        <b>Up &amp; Go Electric Station</b>
        <br/>
        <span>Public charging from PSE <br/>Up &amp; Go Electric</span>
        <img src={ChargingPinUpGo} alt="" className="up-go-pin"/>
      </div>
      <div className="charger-type">
        <b>{translateString(intl, 'chargingMap.public', 'oublic stations')}</b>
        <br />
        <span>Installed by business or government</span>
        <img src={ChargingPinPublic} alt="" />
      </div>
      {hasSuperChargerStations && (
        <div className="charger-type">
          <b>High Power Stations</b>
          <br />
          <span>DC fast charge or superchargers</span>
          <img src={ChargingPinHighPower} alt="" />
        </div>
      )}
      <div className="charger-type">
        <b>Other Types of Stations</b>
        <br />
        <span>Private stations</span>
        <img src={ChargingPinDefault} alt="" />
      </div>
    </div>
  );
};

ChargingStationsMapLegend.propTypes = {
  chargingStations: PropTypes.array
};

ChargingStationsMapLegend.defaultProps = {
  chargingStations: []
};

export default ChargingStationsMapLegend;
