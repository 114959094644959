// @TODO abstract this out. One approach - upAndGo is contained within zip array, and that has the array of assets.
// Not sure if we'll be handling this issue in this manner though.
const upAndGoStations = {
    "98509": [
        "676 Woodland Square Loop SE"
    ],
    "98032" : [
        "24437 Russell Road"
    ],
    "98226": [
       "315 Weterly Rd"
    ],
    "98501": [
       "201 Simmons St NW"
    ],
    "98221": [
       "1207 Q Ave"
    ],
    "98390": [
       "13608 Cannery Wy"
    ],
    "98312": [
       "4650 Werner Rd"
    ]
};

const checkUpAndGoStation = (zip, street) => {
    if(upAndGoStations[zip]) {
        return upAndGoStations[zip].indexOf(street) > -1;  
      }
    
      return false;
}

export default checkUpAndGoStation;