import React from "react";
import PropTypes from "prop-types";

import IncentiveCard from "../IncentiveCard/IncentiveCard";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const IncentiveCatalog = ({ 
    incentives, 
    titleText,
    limit = null
  }) => {
  if (!incentives) return <LoadingSpinner />;

  var sliderSettings = {
       dots: true,
       arrows: true,
       infinite: false,
       speed: 500,
       slidesToShow: 4,
       slidesToScroll: 1,
       responsive: [
              {
                     breakpoint: 1100,
                     settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                     }
              },
              {
                     breakpoint: 991,
                     settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                     }
              },
              {
                     breakpoint: 600,
                     settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                     }
              },
            ]
  };

  const clonedIncentives = limit ?
    incentives.slice(0, limit) :
    incentives.slice();
  
    const renderCards = clonedIncentives
    ? clonedIncentives.map((incentive, index) => {

       let eligibility = incentive.evaluation && incentive.evaluation.eligibility
  
       if (eligibility === "ineligible") {
         return null
       }

        return (
          <div key={index}>
            <IncentiveCard key={index} incentive={incentive} />
          </div>
        );
      })
    : null;
  

  return (
    <div className="row mt-3 mb-3">
      <div className="col-sm-12">
        {titleText && <p className="h3 text-left">{titleText}</p>}
        <div className="incentive-slider">
              <Slider {...sliderSettings}>
                     {renderCards}
              </Slider>
        </div>
      </div>
    </div>
  );
};

export default IncentiveCatalog;

IncentiveCatalog.propTypes = {
  incentives: PropTypes.array,
  titleText: PropTypes.element,
  includeTotal: PropTypes.bool,
  limit: PropTypes.number
};
