import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from "reactstrap";

import pseLogo from "../../../assets/images/pseLogo.png"
import upAndGoLogo from "../../../assets/images/upAndGoLogo.png";
import "./Header.scss";
import { FormattedMessage } from 'react-intl';

const Header = ({ page }) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <div className="Header">
      <div style={{ padding: 0 }}>
        <Navbar expand="lg">
          <div className="d-sm-block d-lg-none stacked-logos-small-screen">
            <div>
              <a
                href="https://www.pse.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={pseLogo}
                  alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                  className="img-fluid logo-1"
                />
              </a>
            </div>
            <div className="lower-logo">
              <a
                href="https://www.pse.com/pages/electric-cars"
                target="_blank"
                rel="noopener noreferrer"
                className="navbar-brand"
              >
                <img
                  src={upAndGoLogo}
                  alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                  className="img-fluid logo-2"
                />
              </a>
            </div>
          </div>
          <div className="navbar-logo d-sm-none d-lg-block larger-screen-logo">
            <a
              href="https://www.pse.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={pseLogo}
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                className="img-fluid logo-1"
              />
            </a>
          </div>
          {/* @TODO verify that this link is correct */}
          <div className="navbar-logo d-sm-none d-lg-block larger-screen-logo">
            <a
              href="https://www.pse.com/pages/electric-cars"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-brand"
            >
              <img
                src={upAndGoLogo}
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                className="img-fluid logo-2"
              />
            </a>
          </div>
          <NavbarToggler onClick={() => setCollapse(!collapse)} aria-label={collapse ? 'Close navigation' : 'Open navigation'} />
          <Collapse isOpen={collapse} navbar>
            <Nav className="ml-auto" navbar>
              {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "vehicles" ? "Active Page" : null}
                    to="/vehicles"
                    rel="canonical"
                  >
                    <div className="message-div">
                      <span>
                        <FormattedMessage
                          id="browseVehicles"
                          defaultMessage="Browse Vehicles"
                          description="Electric Vehicles Header"
                        />
                      </span>
                    </div>
                  </NavLink>
                </NavItem>
              ) : (null)}
              {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "compare-vehicles" ? "Active Page" : null}
                    to="/compare-vehicles"
                    rel="canonical"
                  >
                    <div className="message-div">
                      <span>
                        <FormattedMessage
                          id="compareVehicles"
                          defaultMessage="Compare Vehicles"
                          description="Electric Vehicles Header"
                        />
                      </span>
                    </div>
                  </NavLink>
                </NavItem>
              ) : (null)}
              {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "vehicles" ? "Active Page" : null}
                    to="/used-vehicles"
                    rel="canonical"
                  >
                    <span>
                      <FormattedMessage
                        id="usedElectricVehicles"
                        defaultMessage="Used Vehicles"
                        description="Used Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : (null)}
              {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "incentives" ? "Active Page" : null}
                    to="/incentives"
                    rel="canonical"
                  >
                    <div className="message-div">
                      <span>
                        <FormattedMessage
                          id="incentives"
                          defaultMessage="Incentives"
                          description="Incentives Header"
                        />
                      </span>
                    </div>
                  </NavLink>
                </NavItem>
              ) : (null)}
              {process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "charging-stations" ? "Active Page" : null}
                    to="/charging-stations"
                    rel="canonical"
                  >
                    <div className="message-div">
                      <span>
                        <FormattedMessage
                          id="chargingStations"
                          defaultMessage="Charging Stations"
                        />
                      </span>
                    </div>
                  </NavLink>
                </NavItem>
              ) : (null)}
              {(
                <NavItem>
                  <NavLink
                    title={null}
                    to="/"
                    exact={true}
                    rel="canonical"
                  >
                    <div className="message-div">
                      <span>
                        <FormattedMessage
                          id="EV Guide"
                          defaultMessage="EV Guide"
                        />
                      </span>
                    </div>
                  </NavLink>
                </NavItem>
              )}
              {process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK ? "Active Page" : null}
                    to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}
                  >
                    <span>{process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}</span>
                  </NavLink>
                </NavItem>
              ) : (null)}
              {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "faq" ? "Active Page" : null}
                    to="/faq"
                  >
                    <span>FAQ</span>
                  </NavLink>
                </NavItem>
              ) : (null)}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string
};