import React from "react";
import PropTypes from "prop-types";
import ShowHideButton from "../ShowHideButton/ShowHideButton";
import { FormatCarName, FormatAsInt } from "../../utils/Helpers/Format"
import "chartjs-plugin-datalabels";
import { HorizontalBar } from "react-chartjs-2";
import Uuid from "../../utils/Uuid/Uuid"


const CHART_JS_OPTIONS = {
  maintainAspectRatio: false,
  tooltips: {
    callbacks: {
      label: function(tooltipItem, data) {
        var value =
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        var label = data.datasets[tooltipItem.datasetIndex].label || "";
        value = FormatAsInt(value);
        return " " + label + ": " + value;
      },
      title: function(tooltipItems, data) {
        let total = tooltipItems.reduce(function(acc, item) {
          return acc + item.xLabel;
        }, 0);
        return tooltipItems[0].yLabel + ": " + FormatAsInt(total);
      }
    }
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
          callback: function(value) {
            return value;
          },
          beginAtZero: true
        },
        scaleLabel: {
            display: true,
            labelString: "Miles",
            fontSize: 10,
            fontColor: "#2b2b2b"
        }
      }
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          font: {
            weight: 700
          }
        }
      }
    ]
  },
  legend: {
    position: "bottom",
    onClick: function(e) {
      e.stopPropagation();
    }
  },
  layout: {
    padding: {
      right: 75
    }
  },
  plugins: {
    datalabels: {
      formatter: (value, ctx) => {
        let datasets = ctx.chart.data.datasets;
        if (ctx.datasetIndex === datasets.length - 1) {
          let sum = 0;
          datasets.forEach(dataset => {
            sum += dataset.data[ctx.dataIndex];
          });
          return `${FormatAsInt(sum)} miles`;
        } else {
          return "";
        }
      },
      align: "end",
      anchor: "end",
      color: "#333333",
      font: {
        weight: 700
      }
    },
  }
};

const ElectricRangeGraph = ({
  cars,
  hasExtraPadding,
}) => {

  if (!cars || cars.length === 0) return null;

  cars = cars.filter( car => {
    return car !== null
  })

  cars = cars.map(car => {
      if (car.electric_range === "N/A") {
          car.electric_range = 0
      }
      car["gasoline_range"] = car.total_range - car.electric_range 
      return car
  })



  const sortedCarRange = [...cars].sort((car1, car2) => {
    return car2.electric_range - car1.electric_range
  })

  const subTitle = cars => {
    if (cars.length === 2) {
      const costDelta = cars[1].electric_range - cars[0].electric_range
      const costDeltaText = costDelta < 0 ? "greater" : "fewer";
  
      return (
        <p className="h3 my-3 graph-sub-title">
          The {FormatCarName(cars[0])}'s electric range is {costDeltaText} by {" "}
          <strong style={{ fontWeight: 800 }}>
            {FormatAsInt(Math.abs(costDelta))}
          </strong>{" "}
          miles 
        </p>
      );
    } else {
      return (
        <p className="h3 my-3 graph-sub-title">
          The {FormatCarName(sortedCarRange[0])} has{" "}
          the longest Electric Range
        </p>
      )
    }
  }

  const title = (
    <>
      <p className="h3 my-3">Electric Range</p>
      {subTitle(cars)}
    </>
  )

  const carNames = cars.map(car => FormatCarName(car))

  const chartData = {
    labels: carNames,
    datasets: [
      {
        label: "Electric Range",
        backgroundColor: "#59BEC9",
        data: cars.map(car => car.electric_range)
      },
      {
        label: "Gasoline Range",
        backgroundColor: "#73767B",
        data: cars.map(car => car.gasoline_range)
      }
    ]
  };

  const descriptionRow = (description) => {
    return cars.map(car => {
        return (
        <td key={Uuid()}>{FormatAsInt(car[description])}</td>
      )
    })
  }

  const descriptionRows = 
    (
        <>
            <tr>
                <th scope="row">Electricity</th>
                    {descriptionRow("electric_range")}
            </tr>
            <tr>
                <th scope="row">Gasoline</th>
                    {descriptionRow("gasoline_range")}
            </tr>
        </>
    )

  const totalRow = (
    <tr>
        <th scope="row">Total</th>
            {descriptionRow("total_range")}
    </tr>
  )

  const graphValueColumns = cars.map((car) => {
      return (
        <th key={Uuid()} scope="col">{FormatCarName(car)}</th>
      )
  })

  return (
    <>
    <div
      className="input-well text-center"
      style={hasExtraPadding ? { padding: "2.5rem" } : { paddfing: "1rem" }}
    >
      {title}
      <div className="d-none d-lg-block d-xl-block">
        <div>
          <HorizontalBar
            data={chartData}
            height={212}
            type="horizontalBar"
            options={CHART_JS_OPTIONS}
          />
        </div>
      </div>
      {hasExtraPadding && <br />}
      </div>
      <div className="text-center graph-values-button">
      <ShowHideButton buttonText="DETAILED CALCULATIONS" displayChildrenOnlyOnTablet>
        <br />
        <div className="row">
          <div className="col-sm-12">
            <table className="table table-sm table-bordered">
              <thead>
                <tr>
                <th scope="col">Description</th>
                    {graphValueColumns}
                </tr>
              </thead>
              <tbody>
                {descriptionRows}
              </tbody>
              <tfoot>{totalRow}</tfoot>
            </table>
          </div>
        </div>
      </ShowHideButton>
    </div>
    </>
  );
};

export default ElectricRangeGraph;

ElectricRangeGraph.propTypes = {
  cars: PropTypes.array,
  hasExtraPadding: PropTypes.bool
};
