import React from "react";
import PropTypes from "prop-types";
import { useIntl } from 'react-intl';

import LinkCard from   "../../../../components/LinkCard/LinkCard";
import { intlReturner, translateString } from "../../../../functions/intl/intl";

const HomepageBigPromise = ({
  homePageBannerImage
}) => {
  const intl = intlReturner(useIntl); 

  return (
    <section className="p-0 homepage-hero">
      <div className="big-promise" style={{ position: "relative" }}>
        <div
          style={{
            backgroundImage: "url(" + homePageBannerImage + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            WebkitBackgroundSize: "cover",
            MozBackgroundSize: "cover",
            OBackgroundSize: "cover",
            backgroundSize: "cover",
            padding: 0,
            textAlign: "center",
            height: "550px",
            boxSizing: "border-box"
          }}
        />
        <div className="h0">
          <div className="text-center">{translateString(intl, 'yourGuide', 'Your guide to electric vehicles')}</div>
        </div>
      </div>    
      <div id="link-card-buttons">
        <div className="row">
          <div className="col">
            <div className="link-card-container">
              <LinkCard type="BROWSE_VEHICLES" />
              <LinkCard type="DISCOVER_INCENTIVES" />
              <LinkCard type="LOCATE_CHARGING_STATIONS" />
              <LinkCard type="UP_AND_GO" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string
}