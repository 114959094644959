import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import ButtonGroup from "../../shared/InputElements/ButtonGroup";

const ChooseCanTurnInClunker = ({
  id = "can-turn-in-clunker",
  label = "Are you planning to turn in a clunker?",
  incentives,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);

  return (
    <ButtonGroup
      id={id}
      value={userPrefs.get("canTurnInClunker").toString()}
      label={label}
      optionNames={["Yes", "No"]}
      optionValues={["true", "false"]}
      handler={(e, selected) =>
        userPrefs.set({ canTurnInClunker: selected === "true" })
      }
      {...rest}
    />
  );
};

export default ChooseCanTurnInClunker;

ChooseCanTurnInClunker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  incentives: PropTypes.array
};