import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
// import {FormattedMessage} from 'react-intl';

import twitterIcon from "../../../assets/images/icons/social/Twitter.svg";
import facebookIcon from "../../../assets/images/icons/social/Facebook.svg";
import linkedInIcon from "../../../assets/images/icons/social/LinkedIn.svg";
import youTubeIcon from "../../../assets/images/icons/social/YouTube.svg";
import pseWhite from "../../../assets/images/pse-logo-white.svg";
import upGoWhite from "../../../assets/images/upgo-logo-white.svg";
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";


const Footer = ({ language, changeLanguage }) => {

  /*
  const languageOptions = () => {
    return (
      <>
        <option
          value={'ES'}
          key={'ES'}
        >
          {'Spanish'}
        </option>
      </>
    )
  }

  const onChangeLanguage = e => {
    changeLanguage(e.target.value)
    SmoothScroll("root")
  }
  */

  return (
    <div className="Footer">
      <section className="container text-center">
        <div className="row footer-links">
          <div className="col-sm-12">
            {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (<>
              <Link to="/vehicles" rel="canonical">{process.env.REACT_APP_PAGES_VEHICLES_TITLE}</Link>
            </>) : (null)}
            {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (<>
              <Link to="/compare-vehicles" rel="canonical">{process.env.REACT_APP_PAGES_COMPARE_TITLE}</Link>
            </>) : (null)}
            {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (<>
              <Link to="/incentives" rel="canonical">{process.env.REACT_APP_PAGES_INCENTIVES_TITLE}</Link>
            </>) : (null)}
            {process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (<>
              <Link to="/charging-stations" rel="canonical">{process.env.REACT_APP_PAGES_CHARGING_TITLE}</Link>
            </>) : (null)}
            {process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED ? (<>
              <Link to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}>
                {process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}</Link>
            </>) : (null)}
            {process.env.REACT_APP_PAGES_DEALERS_ENABLED ? (<>
              <Link to="/dealers" rel="canonical">{process.env.REACT_APP_PAGES_DEALERS_TITLE}</Link>
            </>) : (null)}
            {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (<>
              <Link to="/faq" rel="canonical">{process.env.REACT_APP_PAGES_FAQ_TITLE}</Link>
            </>) : (null)}
            <>
              <a
                href="https://www.pse.com/pages/electric-cars"
                target="_blank"
                rel="noopener noreferrer canonical"
              >
                <span>
                  Up &amp; Go Electric
                </span>
              </a>
            </>
            <>
              <a
                href="https://www.pse.com/"
                target="_blank"
                rel="noopener noreferrer canonical"
              >
                <span>
                  PSE.com
                </span>
              </a>
            </>
          </div>
        </div>
        <div className="row contact-us">
          <div className="col-sm-12">
            <p>
              Have a question about electric vehicles? Click <a href="https://www.pse.com/rebates/ask-advisor-form" target="_blank" rel="noopener noreferrer">here</a> or email us at <a href="mailto:electricvehicle@pse.com">electricvehicle@pse.com</a>. Sign up for our monthly <strong>Up &amp; Go Electric</strong> newsletter <a href="https://www.pse.com/pages/electric-cars/electric-cars-newsletter-sign-up" target="_blank" rel="noopener noreferrer">here</a>.
            </p>
          </div>
        </div>
        <div className="row footer-social-icons">
          <div className="col-sm-12">
            <a
              href="https://twitter.com/PSETalk"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={twitterIcon}
                alt="twitter"
              />
            </a>
            <a
              href="https://www.facebook.com/pugetsoundenergy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={facebookIcon}
                alt="facebook"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/pugetsoundenergy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={linkedInIcon}
                alt="linkedin"
              />
            </a>
            <a
              href="https://www.youtube.com/user/PugetSoundEnergy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={youTubeIcon}
                alt="youtube"
              />
            </a>
          </div>
        </div>
        <div className="row copyright">
          <div className="col-sm-12">
            <span style={{ fontFamily: "Arial, Helvetica", marginLeft:"-350px", fontSize:"14px" }}>&copy;
            {(new Date().getFullYear().toString())} {process.env.REACT_APP_FULL_COMPANY_NAME}. All Rights Reserved.
            </span>
            <div className="disclaimer_container">
            <DisclaimerComponent clientName="zappynobackground"  textStyle={{fontFamily:"Arial, Helvetica",fontSize:"14px"}}
             imageStyle={{ 
    marginTop: '-2px',
    marginLeft: '450px'
  }}></DisclaimerComponent>
          </div>

          </div>
          {/* <div className="col-sm-12">
            <p style={{ textAlign: "center" }}>
              <span style={{ fontFamily: "Arial, Helvetica" }}>
                <a rel="noopener noreferrer" target="_blank" href="https://zappyride.com/legal-notices">Legal notices.</a>
              </span>
            </p>
          </div> */}
          {/* <div className="col-sm-12">
            <p>
              <span style={{ fontFamily: "Arial, Helvetica" }}>
                Powered by J.D. Power ZappyRide.
              </span>
            </p>
          </div> */}
        </div>
        <div className="footer-branded-links">
          <div>
            <a
              href="https://www.pse.com"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-brand"
            >
              <img
                src={pseWhite}
                alt="PSE white logo"
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.pse.com/pages/electric-cars"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-brand"
            >
              <img
                src={upGoWhite}
                alt="Up &amp; Go white logo"
              />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
