import React from "react";
import { useIntl } from "react-intl";

import EcoGraph from "../EcoGraph/EcoGraph";

import { intlReturner, translateString } from "../../functions/intl/intl";

import co2graph from "../../client_customizations/assets/images/graphs/CO2-graph.svg";
import powermixgraph from "../../client_customizations/assets/images/graphs/power-mix-graph.svg";

import "./HomepageEcology.scss";

const HomepageEcology = () => {
  const intl = intlReturner(useIntl);

  const ecoTitle = translateString(
    intl,
    "homepage.eco.title",
    "Going electric is good for the planet."
  );
  const ecoSubtitle = translateString(
    intl,
    "homepage.eco.subtitle",
    "Washington's power mix etc etc."
  );

  const ecoGraphs = (
    <>
      <div className="sm-col-12 col-md-12 col-lg-6 co2-graph">
        <EcoGraph width="305px" graphGraphic={co2graph} />
        <p className="co2-title">Annual Emissions*</p>
        <p className="co2-subtitle">
          *Pounds CO<sub>2</sub> equivalent per vehicle, based on ~12,000
          miles/year
        </p>
        <p className="co2-subtitle">As of 2022. Data courtesy of AFDC.</p>
      </div>
      <div className="sm-col-12 col-md-12 col-lg-6 power-mix-graph">
        <EcoGraph width="550px" graphGraphic={powermixgraph} />
        <p className="co2-title mtop">Washington power mix</p>
        <p className="co2-subtitle">As of 2021. Data courtesy of EIA.gov.</p>
      </div>
    </>
  );

  return (
    <section className="homepage-ecology">
      <section className="container">
        <div className="row">
          <div className="col-sm-12 text-center eco-title">
            <h2>{ecoTitle}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 offset-md-3 text-center eco-subtitle">
            <p>{ecoSubtitle}</p>
          </div>
        </div>
        <div className="row eco-graphs">{ecoGraphs}</div>
      </section>
    </section>
  );
};

export default HomepageEcology;
