import React from "react";
import PropTypes from "prop-types";

import SlideElectricMilesPortionForPhev from "../InputComponents/SlideElectricMilesPortionForPhev/SlideElectricMilesPortionForPhev";
import SlideInterestRateAsBasisPoints from "../InputComponents/SlideInterestRateAsBasisPoints/SlideInterestRateAsBasisPoints";
import SlideMilesDrivenAnnually from "../InputComponents/SlideMilesDrivenAnnually/SlideMilesDrivenAnnually";
import SlideMonthsOfOwnership from "../InputComponents/SlideMonthsOfOwnership/SlideMonthsOfOwnership";
import SelectPurchaseMethod from "../InputComponents/SelectPurchaseMethod/SelectPurchaseMethod";
import SlideGasolinePrice from "../InputComponents/SlideGasolinePrice/SlideGasolinePrice";
import isPHEV from "../../functions/vehicle/isPHEV";
import SlideSalesTax from "../InputComponents/SlideSalesTax/SlideSalesTax";
import SlideCostPublicCharging from "../InputComponents/SlideCostPublicCharging/SlideCostPublicCharging";
import SlidePortionPublicCharging from "../InputComponents/SlidePortionPublicCharging/SlidePortionPublicCharging";

const CostOfOwnershipOptions = ({ cars }) => {
  if (!cars || cars.length === 0) return null
  const phevExists = cars.some(car => isPHEV(car))

  let renderElectricMilesPortionForPhev = phevExists ? (
    <SlideElectricMilesPortionForPhev />
  ) : null;

  return (
    <div className="input-well">
      <SelectPurchaseMethod />
      <SlideMilesDrivenAnnually />
      {renderElectricMilesPortionForPhev}
      <SlideMonthsOfOwnership />
      <SlideInterestRateAsBasisPoints />
      <SlideGasolinePrice />
      {process.env.REACT_APP_SLIDE_SALES_TAX && <SlideSalesTax />}
      {process.env.REACT_APP_SLIDE_COST_PUBLIC_CHARGING &&<SlideCostPublicCharging />}
      {process.env.REACT_APP_SLIDE_PORTION_PUBLIC_CHARGING && <SlidePortionPublicCharging />}
    </div>
  );
};

CostOfOwnershipOptions.propTypes = {
  car: PropTypes.object
};
export default CostOfOwnershipOptions;
