import React, { useContext } from "react";
import {FormattedMessage, useIntl} from 'react-intl';
import Switch from "react-switch";
import PropTypes from "prop-types";

import { intlReturner, translateString } from "../../../functions/intl/intl";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import ToolTip from "../../../components/shared/ToolTip/ToolTip";

import IconBEV from "../../assets/images/icons/icon-electric.svg";
import IconPHEV from "../../assets/images/icons/icon-hybrid.svg";

const FuelFilterControls = ({fuelTypeFilterData}) => {
    const intl = intlReturner(useIntl); 
    const userPrefs = useContext(UserPrefsContext);
    const renderedFuelTypeFilters = Object.keys(fuelTypeFilterData).map(
        (filterKey, i) => {
          let filterName = "";
          let filterIconSrc = "";
          switch (filterKey) {
            case "bev":
              filterName = intl.formatMessage ? intl.formatMessage({ id: "vehicle.allElectric", defaultMessage: "All Electric"}) : "All Electric";
              filterIconSrc = IconBEV;
              break;
            case "phev":
              filterName = intl.formatMessage ? intl.formatMessage({ id: "vehicle.plugInHybrid", defaultMessage: "Plug in Hybrid"}) : "Plug in Hybrid";
              filterIconSrc = IconPHEV;
              break;
            default:
          }

          const updateFuelTypeFilter = (checked, event, selectedKey) => {
            const newFilters = Object.keys(fuelTypeFilterData).reduce(function(
              acc,
              key
            ) {

              // Toggle selected key. Other key must be true no matter what.
              acc[key] = key === selectedKey ? !fuelTypeFilterData[key] : true;
              return acc;
            },
            {});
        
            userPrefs.set({
              vehicleFuelTypeFilters: newFilters
            });
          };
    
          return (
            <div key={i} className="filter-switch">
                    <span className="badge">
                          <img src={filterIconSrc} alt="" />
                    </span>
                    <span className="name">{filterName}</span>
                    <div className="switch">
                      <Switch
                        id={filterKey}
                        title={filterName}
                        checked={fuelTypeFilterData[filterKey] ? true : false }
                        onChange={updateFuelTypeFilter}
                        onColor="#006671"
                        offColor="#797979"
                        uncheckedIcon={false}
                        checkedIcon={false}
                      />
                    </div>
                    <div className="clearfix"></div>
            </div>
          );
        }
      );
    
    return (
        <div className="form-group">
          <span className="label-style">
            <FormattedMessage 
              id="evfilter.fuel"
              defaultMessage="Fuel"
              description="Fuel"
            />
            <ToolTip
              message={translateString(
                  intl, 
                  "evfilter.fuelTooltip",
                  "Tooltip for fuel types"
              )}
              id="fuel_tooltip"
            />
          </span>
          <div className="btn-block-container fuel-filter-controls">{renderedFuelTypeFilters}</div>
        </div>
    );
}

export default FuelFilterControls;

FuelFilterControls.propTypes = {
    fuelTypeFilterData : PropTypes.object
}