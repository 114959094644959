import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Range.scss";
import ToolTip from "../ToolTip/ToolTip";

const Range = props => {
  const {
    id,
    value,
    handler,
    label,
    rangeMin,
    rangeMax,
    rangeStep,
    description,
    tooltip,
    ariaControls,
    hasSupportingInfoBelow,
    disabled
  } = props;

  const [workingValue, setWorkingValue] = useState(value);
  const [prevValue, setPrevValue] = useState(null);

  if (value !== prevValue) {
    setWorkingValue(value);
    setPrevValue(value);
  }

  return (
    <div
      className="form-group"
      style={{
        marginTop: "8px",
        marginBottom: hasSupportingInfoBelow ? "4px" : "1rem"
      }}
    >
      <label htmlFor={id}>
        {label}
        {tooltip && <ToolTip message={tooltip} id={id + "_tooltip"} />}
      </label>
      <input
        type="range"
        id={id}
        className="form-control-range input-range"
        value={workingValue}
        min={rangeMin}
        max={rangeMax}
        step={rangeStep}
        disabled={disabled}
        aria-controls={ariaControls}
        // On change, set workingState:
        onChange={e => setWorkingValue(e.target.value)}
        // When finished changing, set global app state:
        onMouseUp={e => handler(e)}
        onKeyUp={e => handler(e)}
        onTouchEnd={e => handler(e)}
      />
      <p className="mb-0">{description(workingValue)}</p>
    </div>
  );
};

export default Range;

Range.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handler: PropTypes.func,
  label: PropTypes.string,
  rangeMin: PropTypes.number,
  rangeMax: PropTypes.number,
  rangeStep: PropTypes.number,
  description: PropTypes.func,
  disabled: PropTypes.bool,
  hasSupportingInfoBelow: PropTypes.bool,
  ariaControls: PropTypes.string,
  tooltip: PropTypes.string
};
