// useIntl is not available in unit testing scenario, causing some tests to fail
// @TODO intlFunc is a function in jest also. The issue is formatMessage
const intlReturner = (intlFunc) => {
    const jestIntl = () =>  { 
        return {
            formatMessage: (msg) => {
                return msg;
            }
        }  
    }
 
    return (typeof intlFunc === 'function') ?
        intlFunc() :
        jestIntl();
}

/**
 * Applies useIntl().formatMessage
 * 
 * @param {*} intl               Return value of useIntl() 
 * @param {String} id            Index used in en.js and esp.js to return text for each language
 * @param {String} defaultMsg    Message to display if not present in en.js or esp.js 
 * @param {Object} values        KVP's to insert text, especially for handling html
 * 
 * @returns {String}
 */
const translateString = (intl, id, defaultMsg, values = {}) => {
    if(!intl.formatMessage) {
        return defaultMsg;
    }

    return intl.formatMessage({
        id, 
        defaultMsg,
        values
    });
}

export { 
    intlReturner,
    translateString
}